'use client';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'app/components/Loader';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function Auth0LoginPage() {
  const { loginWithRedirect } = useAuth0();

  const currentSearchParams = useSearchParams();

  useEffect(() => {
    void loginWithRedirect({
      openUrl: (url) => {
        const newUrl = new URL(url);

        // Pass URL params like org invitation and password reset tokens to Auth0
        for (const [key, value] of currentSearchParams.entries()) {
          newUrl.searchParams.append(key, value);
        }

        window.location.replace(newUrl.toString());
      },
    });
  }, [loginWithRedirect, currentSearchParams]);

  return <Loader type="fullpage" />;
}
